import { type Ref, computed, onBeforeUnmount, ref, watch } from 'vue'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { useIntersectionObserver } from '@vueuse/core'

import type { UserCommentProps } from './UserCommentView.vue'

export function useTrackReviewImpression(
  target: Ref<null | HTMLElement>,
  {
    userComment,
    isTranslated,
  }: {
    userComment: UserCommentProps
    isTranslated: Ref<boolean>
  },
) {
  const hasTriggeredImpression = ref(false)

  const { trackReviewImpression } = useTracking()

  const {
    market: { countryCode: currentCountryCode },
  } = useMarketplace()
  const isReviewFromCurrentCountry = computed(
    () => userComment.countryCode === currentCountryCode,
  )

  const { stop } = useIntersectionObserver(target, ([{ isIntersecting }]) => {
    if (isIntersecting) {
      const { countryCode, product } = userComment
      trackReviewImpression({
        ...product,
        averageRate: userComment.averageRate,
        countryCode,
        isEdited: !!userComment.handledWithCare,
        isHighlighted: !!userComment.isHighlighted,
        isReviewFromCurrentCountry: isReviewFromCurrentCountry.value,
        isReviewTranslated: isTranslated.value,
        ...(userComment.trackingData?.onImpression || {}),
      })

      hasTriggeredImpression.value = true
    }
  })

  watch(hasTriggeredImpression, () => {
    if (hasTriggeredImpression.value) {
      stop()
    }
  })

  onBeforeUnmount(() => {
    stop()
  })
}
