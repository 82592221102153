export default {
  reviewDate: {
    id: 'product_reviews_user_comment_review_date',
    defaultMessage: 'Reviewed in {country} on {date}',
  },
  purchaseDate: {
    id: 'product_reviews_user_comment_purchase_date',
    defaultMessage: 'Purchased on {date}',
  },
  reviewUpdateDate: {
    id: 'product_reviews_user_comment_update_date',
    defaultMessage: 'Updated in {country} on {date}',
  },
  showMore: {
    id: 'product_reviews_user_comment_more',
    defaultMessage: 'Show more',
  },
  showLess: {
    id: 'product_reviews_user_comment_less',
    defaultMessage: 'Show less',
  },
  textTranslatedFrom: {
    id: 'translated_from',
    defaultMessage: 'Translated from {lang}',
  },
  seeOriginalText: {
    id: 'see_original_text',
    defaultMessage: 'See original',
  },
  translateTextTo: {
    id: 'translate_to',
    defaultMessage: 'Translate to {lang}',
  },
  errorToastTitle: {
    id: 'translation_error_title',
    defaultMessage: 'Oops, something went wrong.',
  },
  errorToastMessage: {
    id: 'translation_error_message',
    defaultMessage:
      'Our server is a bit slow at the moment. Please give it a sec and try again.',
  },
  handledWithCare: {
    id: 'product_reviews_handled_with_care_header',
    defaultMessage:
      'The customer updated their review after getting in touch with {text, html}.',
  },
  handledWithCareInnerText: {
    id: 'product_reviews_handled_with_care_header_bold',
    defaultMessage: 'Customer Care',
  },
  originalReview: {
    id: 'product_reviews_original_review',
    defaultMessage: 'Original review',
  },
  anonymousUser: {
    id: 'reviews_anonymous_customer',
    defaultMessage: 'An anonymous Backer',
  },
} as const
