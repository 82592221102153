import { type MaybeRefOrGetter, computed, ref, toValue, watch } from 'vue'

import type { Locale } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import translations from './UserComment.translations'

export function useTranslateComment(
  review: MaybeRefOrGetter<{
    translatedComment?: string
    comment: string
    originalReview?: {
      averageRate: number
      comment: string
      countryCode: string
      createdAt: string
    }
    languageCode: string
    countryCode: string
  }>,
) {
  const {
    market: { countryCode: currentCountryCode },
  } = useMarketplace()
  const i18n = useI18n()
  const locale = useI18nLocale()
  const isTranslatable = computed(
    () => toValue(review).countryCode !== currentCountryCode,
  )
  const isTranslated = ref(!!toValue(review).translatedComment)

  watch(
    () => toValue(review).translatedComment,
    (newTranslatedComment) => {
      if (newTranslatedComment) {
        isTranslated.value = true
      }
    },
  )

  const mainReviewComment = computed<string>(() => {
    const { translatedComment } = toValue(review)

    if (!translatedComment) return toValue(review).comment

    return isTranslated.value ? translatedComment : toValue(review).comment
  })

  const language = computed(() => {
    if (isTranslated.value) {
      return i18n.language(toValue(review).languageCode as Locale)
    }

    const lang = locale.split('-')[0] || locale

    return i18n.language(lang as Locale)
  })

  const toggleTranslationLabel = computed(() => {
    return isTranslated.value
      ? i18n(translations.seeOriginalText)
      : i18n(translations.translateTextTo, { lang: language.value })
  })

  const translatedFrom = computed(() =>
    i18n(translations.textTranslatedFrom, { lang: language.value }),
  )

  function toggleTranslation() {
    // only toggle if there is a translatedComment
    if (toValue(review).translatedComment)
      isTranslated.value = !isTranslated.value
  }

  const editedReviewIsDifferentFromOriginal = computed(
    () => mainReviewComment.value !== toValue(review).originalReview?.comment,
  )

  return {
    mainReviewComment,
    editedReviewIsDifferentFromOriginal,
    isTranslated,
    toggleTranslation,
    toggleTranslationLabel,
    translatedFrom,
    isTranslatable,
  }
}
