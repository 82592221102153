<template>
  <div v-if="comment" class="mt-32">
    <p class="body-1 block whitespace-pre-line">
      {{ displayedText }}
    </p>

    <RevLink v-if="isCommentLong" class="mt-2" @click="onToggleText">
      {{ toggleTextLabel }}
    </RevLink>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevLink } from '@ds/components/Link'

import translations from './UserComment.translations'

const props = withDefaults(
  defineProps<{
    comment: string
    tracking?: Record<string, unknown>
    forceExpand?: boolean
  }>(),
  { forceExpand: false, tracking: () => ({}) },
)
const MAX_COMMENT_LENGTH = 1200

const i18n = useI18n()
const { trackClick } = useTracking()

const cutComment = ref(true)

const isCommentLong = computed(() => props.comment.length > MAX_COMMENT_LENGTH)

const displayedText = computed(() => {
  if (isCommentLong.value && cutComment.value) {
    return `${props.comment.substring(0, MAX_COMMENT_LENGTH)}...`
  }

  return props.comment
})

const toggleTextLabel = computed(() =>
  cutComment.value ? i18n(translations.showMore) : i18n(translations.showLess),
)

function onToggleText() {
  trackClick({
    ...props.tracking,
    name: cutComment.value ? 'see_more' : 'see_less',
  })

  cutComment.value = !cutComment.value
}

watch(
  () => props.forceExpand,
  () => {
    cutComment.value = !props.forceExpand
  },
)
</script>
