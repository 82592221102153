<template>
  <RevDrawer
    :close-button-label="i18n(translations.alternativeClose)"
    :large="true"
    :name="`user-comment-modal-${review.id}`"
    :title="review.product.title"
  >
    <template #trigger="{ open }">
      <slot name="trigger" :open="open" />
    </template>

    <template #body>
      <div class="gap-24 md:flex">
        <div v-if="pictures.length === 1" class="h-full w-full md:w-1/2">
          <RevIllustration
            alt=""
            class="rounded-lg max-h-full max-w-full object-contain"
            :height="480"
            loading="eager"
            sizes="(min-width: 768px) 448px, 328px"
            :src="pictures[0]"
            :width="448"
          />
        </div>

        <RevCarousel
          v-else
          :alternative-button-label="i18n(translations.alternativeController)"
          :alternative-next="i18n(translations.alternativeNext)"
          :alternative-previous="i18n(translations.alternativePrevious)"
          class="h-full w-full md:w-1/2"
          :close-button-label="i18n(translations.alternativeClose)"
          current-index-id="gallery-media-viewer"
          name="review-media-viewer"
          :thumbnails
        >
          <RevIllustration
            v-for="(image, index) in pictures"
            :key="image"
            alt=""
            class="rounded-lg max-h-full max-w-full object-contain"
            data-test="user-comment-photos-slider-picture"
            :height="480"
            :loading="shouldLoadEagerly(index)"
            sizes="(min-width: 768px) 448px, 328px"
            :src="image"
            :width="448"
          />
        </RevCarousel>
        <RevDivider class="my-32 md:hidden" />

        <UserCommentView v-bind="review" class="md:w-1/2" />
      </div>
    </template>
  </RevDrawer>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCarousel } from '@ds/components/Carousel'
import { RevDivider } from '@ds/components/Divider'
import { RevDrawer } from '@ds/components/Drawer'
import { RevIllustration } from '@ds/components/Illustration'

import UserCommentView, {
  type UserComment,
} from '../UserCommentView/UserCommentView.vue'

import translations from './UserComment.translations'

const props = defineProps<{
  pictures: Array<string>
  review: UserComment
}>()

const i18n = useI18n()

function shouldLoadEagerly(index: number) {
  return index === 0 ? 'eager' : 'lazy'
}

const thumbnails = computed(() => {
  return props.pictures.map((pictureUrl) => ({
    src: pictureUrl,
    width: 100,
    height: 100,
    alt: '',
  }))
})
</script>
